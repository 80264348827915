function showLoadingOverlay(title) {
  const overlay = document.querySelector('[data-loading-overlay]');
  document.body.classList.add('scroll-lock');
  
  if (!overlay) {
    console.error('Loading overlay element not found');
    return;
  }

  overlay.classList.add('visible');

  let overlayTitle = overlay.querySelector('.overlay-title');
  if (!overlayTitle) {
    overlayTitle = document.createElement('p');
    overlayTitle.classList.add('overlay-title');
    overlay.insertBefore(overlayTitle, overlay.firstChild);
  }
  
  overlayTitle.textContent = title;
}

function hideLoadingOverlay() {
  document.body.classList.remove('scroll-lock');
  const overlay = document.querySelector('[data-loading-overlay]');
  
  if (!overlay) {
    console.error('Loading overlay element not found');
    return;
  }
  
  overlay.classList.remove('visible');

}

export { showLoadingOverlay, hideLoadingOverlay };
