const languageChange = () => {
  const languageChoices = document.querySelectorAll('.language-choice');
  const userLang = navigator.language || navigator.userLanguage;
  const headerLangSpan = document.querySelector('.header__lang span');

  if (headerLangSpan) {
    if (userLang.startsWith("ru")) {
      headerLangSpan.textContent = "Русский";
    } else {
      headerLangSpan.textContent = "English";
    }
  }
  
  languageChoices.forEach(function (choice) {
    choice.addEventListener('click', function (e) {
      e.preventDefault();

      const chosenLanguage = choice.getAttribute('data-lang');

      if (chosenLanguage === "ru" && headerLangSpan) {
        headerLangSpan.textContent = "Русский";
      } else if (chosenLanguage === "en" && headerLangSpan) {
        headerLangSpan.textContent = "English";
      }
    });
  });
};

export { languageChange };
