import { BASE_URL, getData, postData } from "../api";
import { redirectToPageWithParams } from "../../utils/redirectToPage";
import { getTestType } from "../user/sendTestType";
import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { moveToNextStage } from "../accessControl";

const testType = getTestType();

// города
function fillCitySelect(cities, shops) {
  const citySelect = document.querySelector('select[data-city]');
  if (citySelect) {

    citySelect.innerHTML = '';
    citySelect.addEventListener('change', () => filterAddresses(citySelect.value, shops));

    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.textContent = 'Выберите город';
    defaultOption.disabled = true;
    defaultOption.selected = true;
    citySelect.appendChild(defaultOption);

    cities.forEach(city => {
      const option = document.createElement('option');
      option.value = city;
      option.textContent = city;
      citySelect.appendChild(option);
    });
  }
}
// адреса
function fillAddressSelect(shops, selectedCity = "") {
  const addressSelect = document.querySelector('select[data-address]');
  if (addressSelect) {
    addressSelect.innerHTML = '';

    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.textContent = 'Выберите адрес';
    defaultOption.disabled = true;
    defaultOption.selected = true;
    addressSelect.appendChild(defaultOption);

    const sortedShops = shops.sort((a, b) => {
      const addressA = a.address.toLowerCase();
      const addressB = b.address.toLowerCase();

      if (addressA < addressB) return -1;
      if (addressA > addressB) return 1;
      return 0;
    });

    sortedShops.forEach(shop => {
      const option = document.createElement('option');
      option.value = shop.id;

      let address = shop.address;
      if (selectedCity && address.startsWith(selectedCity)) {
        address = address.slice(selectedCity.length + 2);
      }
      option.textContent = address;
      addressSelect.appendChild(option);
    });

    addressSelect.onchange = () => {
      const selectedId = addressSelect.value;
      const selectedShop = shops.find(shop => shop.id.toString() === selectedId);
      if (selectedShop) {
        ymaps.geocode(`${selectedShop.city}, ${selectedShop.address}`).then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          const coords = firstGeoObject.geometry.getCoordinates();
          myMap.setCenter(coords, 15);
        });
      }
    };
  }
}

function filterAddresses(selectedCity, shops) {
  const filteredShops = shops.filter(shop => shop.city === selectedCity);
  fillAddressSelect(filteredShops, selectedCity);

  ymaps.geocode(selectedCity, { results: 1 }).then(function (res) {
    const firstGeoObject = res.geoObjects.get(0);
    const coords = firstGeoObject.geometry.getCoordinates();
    myMap.setCenter(coords, 10);
  });
}

// получаем от сервака
async function getShopsList() {
  showLoadingOverlay("Загружаем список магазинов");

  try {
    const response = await getData(`${BASE_URL}/shop/shop_list/?test_type=${testType}`);
    const shops = response.data.shops;
    const cities = shops.map(shop => shop.city);
    const uniqueCities = [...new Set(cities)];

    fillCitySelect(uniqueCities, shops);
    fillAddressSelect(shops);

    return { uniqueCities, address: shops };
  } catch (error) {
  } finally {
    hideLoadingOverlay();
  }
}
// иниц карту
var myMap;
function initShopsMap() {
  if (document.getElementById('map')) {
    ymaps.ready(async function init() {
      myMap = new ymaps.Map("map", {
        center: [55.76, 37.64],
        zoom: 10,
        controls: ['zoomControl']
      });

      try {
        const result = await getShopsList();
        const shops = result.address;
        // группировка магазов по городам
        const shopsByCity = shops.reduce((acc, shop) => {
          acc[shop.city] = acc[shop.city] || [];
          acc[shop.city].push(shop);
          return acc;
        }, {});
        // Для каждого города добавляем метки
        for (const city in shopsByCity) {
          shopsByCity[city].forEach(shop => {
            const coords = shop.point.split(',').map(point => parseFloat(point.trim()));

            const balloonContentLayout = ymaps.templateLayoutFactory.createClass(
              `<div class="map__baloon">
                <div class="map__baloon-title">Магнит Косметик</div>
                <div class="map__baloon-addr">${shop.city}, ${shop.address}</div>
                <div class="map__baloon-text">${shop.worktime}</div>
                <div class="map__baloon-subtext">${shop.description}</div>
                <button type="button" class="map__baloon__button btn btn--pink" data-shop-id="${shop.id}">Выбрать магазин</button>
              </div>`, {
              // отправка айди магаза при клике на балун
              build: function () {
                this.constructor.superclass.build.call(this);
                const btn = this.getElement().querySelector('.map__baloon__button');
                btn.addEventListener('click', async () => {
                  const shopId = btn.getAttribute('data-shop-id');
                  try {
                    showLoadingOverlay("Сохраняем выбранный магазин");

                    await postData(`${BASE_URL}/shop/shop/?test_type=${testType}`, {
                      'shop_id': shopId
                    });

                    moveToNextStage();
                    const initiatedByChangeShop = sessionStorage.getItem('initiatedByChangeShop');
                    if (initiatedByChangeShop) {
                      sessionStorage.removeItem('initiatedByChangeShop');
                      redirectToPageWithParams('recs.html', 'test_type');
                    } else {
                      redirectToPageWithParams('photo.html', 'test_type');
                    }
                  } catch (error) {
                    console.error('Ошибка отправки данных:', error);
                  } finally {
                    hideLoadingOverlay();
                  }
                });
              },
            });

            const myPlacemark = new ymaps.Placemark(coords, {
              hintContent: shop.name,
              balloonContent: shop.address
            }, {
              balloonContentLayout: balloonContentLayout,
              preset: 'islands#pinkIcon',
              iconCaptionMaxWidth: "50"
            });

            myMap.geoObjects.add(myPlacemark);
          });
        }
      } catch (error) {
        console.error('Ошибка при инициализации карты:', error);
      }
    });
  }
}

// отправка id магазина
function postShopId() {
  const nextButtons = document.querySelectorAll('.map__next[data-next]');
  if (nextButtons) {
    nextButtons.forEach(btn => btn.addEventListener('click', async function (event) {

      event.preventDefault();
      const addressSelect = document.querySelector('select[data-address]');
      const selectedShopId = addressSelect.value;

      if (selectedShopId) {
        showLoadingOverlay("Сохраняем выбранный магазин");
        try {
          moveToNextStage();
          await postData(`${BASE_URL}/shop/shop/?test_type=${testType}`, {
            'shop_id': selectedShopId,
          });
          const initiatedByChangeShop = sessionStorage.getItem('initiatedByChangeShop');
          if (initiatedByChangeShop) {
            sessionStorage.removeItem('initiatedByChangeShop');
            redirectToPageWithParams('recs.html', 'test_type');
          } else {
            redirectToPageWithParams('photo.html', 'test_type');
          }
        } catch (error) {
        } finally {
          hideLoadingOverlay();
        }
      } else {
        alert('Пожалуйста, выберите магазин.');
      }
    }));
  }
}

const changeShopBtn = document.querySelector('[data-change-shop]')
if (changeShopBtn) {
  changeShopBtn.addEventListener('click', () => {
    sessionStorage.setItem('initiatedByChangeShop', 'true');
    window.location.href = 'map.html';
  });
}


export { getShopsList, initShopsMap, postShopId }