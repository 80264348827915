import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { BASE_URL, postData } from "../api";

async function sendTestType(index) {
  
  try {
    const response = await postData(`${BASE_URL}/core/start_new_test/?test_type=${index}`);
    return response.result
  } catch (error) {
    return false
  }
}

function getTestType() {
  return localStorage.getItem('testType');
}

export { getTestType, sendTestType }