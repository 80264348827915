const updateTestElements = () => {
  const params = new URLSearchParams(window.location.search);
  const currentTestType = params.get('test_type');
  const mainBlock = document.querySelector('[data-type]');
  const recs = document.querySelectorAll('[data-recs] li')

  document.querySelectorAll('[data-next]').forEach(button => {
    button.href = updateQueryParam(button.href, 'test_type', currentTestType);
  });

  document.querySelectorAll('[data-back]').forEach(button => {
    button.href = updateQueryParam(button.href, 'test_type', currentTestType);
  });

  document.querySelectorAll('[data-skip]').forEach(button => {
    button.href = updateQueryParam(button.href, 'test_type', currentTestType);
  });

  if (mainBlock) {
    const imageCont = mainBlock.querySelectorAll('.photo__example picture');
    mainBlock.classList.add(currentTestType);
    imageCont.forEach(image => {
      image.style.display = image.classList.contains(`photo__${currentTestType}`) ? 'block' : 'none';
    });
  }

  if (recs) {
    recs.forEach(rec => {
      rec.style.display = rec.dataset.testType === currentTestType ? 'list-item' : 'none';
    });
  }
};

const updateQueryParam = (url, key, value) => {
  let newUrl = new URL(url, window.location.origin);
  newUrl.searchParams.set(key, value);
  return newUrl.href;
};

export { updateTestElements, updateQueryParam };
