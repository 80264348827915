import { redirectToPageWithParams } from "../../utils/redirectToPage";

function initComparePhoto() {
  const imgContainer = document.querySelector('.recs__photo-cont');
  if (!imgContainer) return;

  const imgCompare = document.querySelector('.recs__photo');
  const overlayImgDiv = imgContainer.querySelector('.recs__photo-over');
  const slider = imgContainer.querySelector('[data-recs-handle]');
  const photoUploaded = localStorage.getItem('photoUploaded') === 'true'

  if (!photoUploaded) {
    imgCompare.innerHTML = ''
    const imgElement = document.createElement('img');
    imgElement.src = "../images/face-ex.png";
    imgElement.srcset = "../images/face-ex@2x.png 2x";
    imgElement.width = 302;
    imgElement.height = 302;
    imgElement.alt = "Пример фотографии";
    const imgLoadLink = document.createElement('a')
    imgLoadLink.textContent = 'Загрузить фото'
    imgLoadLink.classList.add('photo__btn', 'btn', 'btn--pink')
    imgCompare.appendChild(imgElement)
    imgCompare.appendChild(imgLoadLink)
    imgLoadLink.addEventListener('click', () => {
      redirectToPageWithParams('photo.html', 'test_type')
    })
  }

  let isDragging = false;
  let startX = 0;
  let startWidth = 0;

  overlayImgDiv.style.width = '50%';

  let lastUpdateTime = 0;
  const minUpdateInterval = 10;

  slider.addEventListener('mousedown', startDrag);
  slider.addEventListener('touchstart', startDrag);

  function startDrag(event) {
    if (event.type === 'touchstart') {
      startX = event.touches[0].clientX;
    } else {
      startX = event.clientX;
    }

    isDragging = true;
    startWidth = parseInt(getComputedStyle(overlayImgDiv).width, 1);

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchmove', onMouseMove);
    document.addEventListener('touchend', onMouseUp);

    requestAnimationFrame(animate);
  }

  function onMouseMove(event) {
    if (!isDragging) return;

    let clientX;
    if (event.type === 'touchmove') {
      clientX = event.touches[0].clientX;
    } else {
      clientX = event.clientX;
    }

    const now = performance.now();
    if (now - lastUpdateTime < minUpdateInterval) return;

    lastUpdateTime = now;

    const deltaX = clientX - startX;
    const newWidth = startWidth + deltaX;

    const rect = imgContainer.getBoundingClientRect();
    let position = clientX - rect.left;

    position = Math.max(position, 25);
    position = Math.min(position, rect.width);

    overlayImgDiv.style.width = (position - 24) + 'px';
    slider.style.left = position - (slider.offsetWidth / 2) + 'px';
  }


  function onMouseUp() {
    if (!isDragging) return;

    isDragging = false;
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('touchmove', onMouseMove);
    document.removeEventListener('touchend', onMouseUp);
  }

  function animate() {
    if (isDragging) {
      requestAnimationFrame(animate);
    }
  }
}

export { initComparePhoto }