import { getResults } from "../result/getResults";

async function getTestResult() {
  const testId = localStorage.getItem('testId');
  if (testId) {
    const response = await getResults('profile', testId);
  }
}

export { getTestResult };
