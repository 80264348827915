import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { redirectToPageWithParams } from "../../utils/redirectToPage";
import { moveToNextStage } from "../accessControl";
import { BASE_URL, getData, postData } from "../api";
import { getRecsData } from "../recs/getRecsData";
import { getTestType } from "../user/sendTestType";

const testType = getTestType();

// function handleInitialQuestion() {
//   const initialQuestionContainer = document.querySelector("[data-test-init]");
//   if (initialQuestionContainer) {
//     const answerBtns = document.querySelectorAll('button')

//     if (answerBtns) {
//       answerBtns.forEach(btn => {
//         btn.addEventListener('click', async () => {
//           if (btn.hasAttribute('data-test-known')) {
//             await markTestAsSkipped()
//             moveToNextStage()
//             await sendTestData(event, "skip", false)
//           } else {
//             await markTestAsDone()
//             initialQuestionContainer.classList.remove("is-active");
//             initialQuestionContainer.style.display = 'none';
//             // getTestData()
//           }
//         });
//       })
//     }
//   }
// }

class QuestionCard {
  static answers = JSON.parse(localStorage.getItem("test_answers")) || [];

  constructor(question, container) {
    this.question = question;
    this.container = container;
    this.cardElement = document.createElement("div");
    this.initCard();
  }

  initCard() {
    this.cardElement.className = "test__question";
    if (this.question.question_id === 1) {
      this.cardElement.classList.add("is-active");
    }
    this.cardElement.setAttribute(
      "data-question-id",
      `${this.question.question_id}`
    );
    this.addQuestionTitle();
    if (this.question.question_id === 6) {
      this.addTooltip();
    }
    this.addAnswers();
    this.container.appendChild(this.cardElement);
    if (this.question.question_id === 7) {
      this.addFinalSubmitButton(this.cardElement);
    }
  }

  addQuestionTitle() {
    const questionTitle = document.createElement("h2");
    questionTitle.textContent = this.question.text;
    this.cardElement.appendChild(questionTitle);
  }

  addAnswers() {
    const answersList = document.createElement("ul");
    if (this.question.type === 'number') {
      const answerItem = document.createElement("li");
      this.createInput(answerItem);
      answersList.appendChild(answerItem);
    } else {
      this.question.answers.forEach((answer) => {
        const answerItem = document.createElement("li");
        this.createButton(answer, answerItem);
        answersList.appendChild(answerItem);
      });
    }
    this.cardElement.appendChild(answersList);
  }

  createButton(answer, parentElement) {
    const answerButton = document.createElement("button");
    answerButton.textContent = answer.text;

    const selectedAnswer = QuestionCard.answers.find(
      (ans) => ans.question_id === this.question.question_id
    );
    if (selectedAnswer && selectedAnswer.answer_id === answer.answer_id) {
      answerButton.classList.add("is-chosen");
    }

    answerButton.addEventListener("click", () =>
      this.selectAnswer(answer.answer_id, answer.next_question, answerButton)
    );
    parentElement.appendChild(answerButton);
  }

  createInput(parentElement) {
    const ageInput = document.createElement("input");
    ageInput.classList.add("test__age");
    ageInput.type = "number";
    ageInput.id = "age";
    ageInput.max = "100"
    ageInput.min = '1'
    ageInput.step = '1'
    ageInput.name = `question_${this.question.question_id}`;
    ageInput.value = this.question.answer_id || "";
    ageInput.placeholder = this.question.text;
    ageInput.required = true;

    ageInput.addEventListener("input", () => {
      this.selectAnswer(parseInt(ageInput.value, 10), null);
      if (!/^\d*$/.test(ageInput.value)) {
        ageInput.value = ageInput.value.replace(/[^\d]/g, '');
      }

      const value = parseInt(ageInput.value, 10);
      if (value < 1 || value > 100 || isNaN(value)) {
        ageInput.value = '';
      }
    });

    const label = document.createElement("label");
    label.htmlFor = "age";
    parentElement.appendChild(ageInput);
    parentElement.appendChild(label);
    label.className = "visually-hidden";
  }

  updateNextButtonState() {
    const checkboxes = this.cardElement.querySelectorAll(
      'input[type="checkbox"]'
    );
    const anyChecked = Array.from(checkboxes).some(
      (checkbox) => checkbox.checked
    );
    const nextButton = this.cardElement.querySelector(".test__button-next");
    if (nextButton) {
      nextButton.disabled = !anyChecked;
    }
  }

  addTooltip() {
    const tooltip = document.createElement("div");
    tooltip.textContent =
      "Чувствительная кожа склонна к повышенной раздражительности и чрезмерной реакции в ответ на различные факторы (климат, косметику, ингредиенты и т. д.). Важно учесть возможность индивидуальной непереносимости.";
    tooltip.className = "test__tooltip";
    this.cardElement.appendChild(tooltip);
  }

  selectAnswer(answerId, nextQuestionId, buttonElement = null) {
    if (nextQuestionId !== null) {
      const currentQuestion = document.querySelector(".test__question.is-active");
      if (currentQuestion) {
        currentQuestion.classList.remove("is-active");
      }
    }

    QuestionCard.updateAnswer(this.question.question_id, answerId);

    if (buttonElement) {
      const buttons = this.cardElement.querySelectorAll("button");
      buttons.forEach(btn => btn.classList.remove("is-chosen"));
      buttonElement.classList.add("is-chosen");
    }

    if (nextQuestionId !== null) {
      const nextQuestion = document.querySelector(
        `.test__question[data-question-id="${nextQuestionId}"]`
      );
      if (nextQuestion) {
        nextQuestion.classList.add("is-active");
      } else {
        console.error("Следующий вопрос не найден.");
      }
    }
  }

  navigateQuestion(questionId) {
    const currentQuestion = document.querySelector(".test__question.is-active");
    if (currentQuestion) {
      currentQuestion.classList.remove("is-active");
    }

    const targetQuestion = document.querySelector(
      `.test__question[data-question-id="${questionId}"]`
    );
    if (targetQuestion) {
      targetQuestion.classList.add("is-active");
    } else {
      console.error("Вопрос не найден.");
    }
  }

  static updateAnswer(questionId, answerId) {
    const index = QuestionCard.answers.findIndex(
      (ans) => ans.question_id === questionId
    );
    if (index !== -1) {
      QuestionCard.answers[index].answer_id = answerId;
    } else {
      QuestionCard.answers.push({
        question_id: questionId,
        answer_id: answerId,
      });
    }
    localStorage.setItem("test_answers", JSON.stringify(QuestionCard.answers));
  }

  addFinalSubmitButton(parentElement) {
    const submitButton = document.createElement("button");
    submitButton.classList.add("test__send");
    submitButton.textContent = "Отправить";
    submitButton.classList.add("test__button-submit", "btn", "btn--pink");
    submitButton.addEventListener("click", (event) => {
      event.preventDefault();
      sendTestData(event, QuestionCard.answers, true);
    });
    parentElement.appendChild(submitButton);
  }
}

async function getTestData() {
  showLoadingOverlay("Получаем список вопросов");
  try {
    const response = await getData(`${BASE_URL}/core/test/?test_type=${testType}`);
    const questions = response.data.questions;
    const container = document.querySelector(".test__data");

    if (!container) {
      return;
    }

    container.innerHTML = "";

    questions.forEach((question) => {
      new QuestionCard(question, container);
    });
  } catch (error) {
  } finally {
    hideLoadingOverlay();
  }
}

async function markTestAsDone() {
  localStorage.setItem('testSkipped', 'false');
}

async function markTestAsSkipped() {
  localStorage.setItem('testSkipped', 'true');
}

const photoUploaded = localStorage.getItem('photoUploaded') === 'true';
const testSkipped = localStorage.getItem('testSkipped') === 'true'


async function sendTestData(event, data, redirect) {
  event.preventDefault();
  showLoadingOverlay("Сохраняем ответы")
  try {
    await postData(`${BASE_URL}/core/test/?test_type=${testType}`, {
      data: data
    }
    );
    moveToNextStage();
    localStorage.removeItem("test_answers");
    if (redirect && photoUploaded && !testSkipped) {
      redirectToPageWithParams("results.html", "test_type");
    } else {
      redirectToPageWithParams('recs.html', 'test_type')
    }
    getRecsData()
  } catch (error) {
    console.error("Ошибка при отправке ответов:", error);
    console.error(error.config.data);
  } finally {
    hideLoadingOverlay()
  }
}

// handleInitialQuestion()

export { getTestData };
