class ModalStack {
  constructor() {
    this.stack = [];
    this.current = null;
  }

  openModal(modal) {
    if (this.current && this.current !== modal) {
      this.stack.push(this.current);
      this.current.close();
    }
    this.current = modal;
  }

  closeModal() {
    const modal = this.stack.pop() || null;
    this.current = modal;
    if (modal) modal.open();
  }
}

const modalStack = new ModalStack();

class Modal {
  constructor(modalElement) {
    this.modal = modalElement;
    this.initModal();
  }

  initModal() {
    this.modalInner = this.modal.querySelector('[data-js="modal"]');
    this.openTriggers = document.querySelectorAll(`.js-modal[data-modal="${this.modal.dataset.modal}"]`);
    this.closeTriggers = this.modal.querySelectorAll('[data-js="modal-close"]');
    this.isOpen = false;

    this.bindEvents();
    this.checkHashOpen();
  }

  bindEvents() {
    this.openTriggers.forEach(trigger => trigger.addEventListener('click', this.handleOpenTriggerClick.bind(this)));
    this.closeTriggers.forEach(trigger => trigger.addEventListener('click', this.close.bind(this)));
    this.modal.addEventListener('click', this.handleClickOutside.bind(this));
  }

  handleOpenTriggerClick(event) {
    event.preventDefault();
    const shouldClosePrevious = event.currentTarget.dataset.closePrevious === 'true';
    this.open({ closePrev: shouldClosePrevious });
  }

  handleClickOutside(event) {
    if (!this.modalInner.contains(event.target)) this.close();
  }

  checkHashOpen() {
    if (window.location.hash === `#${this.modal.dataset.hashOpen}`) this.open();
  }

  open({ closePrev = false } = {}) {
    if (closePrev) modalStack.closeModal();
    modalStack.openModal(this);
    this.isOpen = true;
    this.modal.classList.add('is-opened');
    document.documentElement.classList.add('scroll-lock');
    this.adjustForViewportHeight();
  }

  close() {
    if (this.isOpen) {
      modalStack.closeModal();
      this.isOpen = false;
      this.modal.classList.remove('is-opened');
      document.documentElement.classList.remove('scroll-lock');
    }
  }

  adjustForViewportHeight() {
    const adjustModalPosition = () => {
      const heightDifference = window.innerHeight - this.modal.offsetHeight;
      this.modal.style.bottom = `${Math.max(heightDifference, 0)}px`;
    };

    window.addEventListener('resize', adjustModalPosition);
    this.modal.addEventListener('hidden', () => window.removeEventListener('resize', adjustModalPosition));
  }
}

if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
  document.documentElement.classList.add('ios-device');
}

document.addEventListener('touchmove', event => {
  if (document.documentElement.classList.contains('scroll-lock') && !event.target.closest('.modal')) {
    event.preventDefault();
  }
}, { passive: false });


document.addEventListener('keydown', event => {
  if (event.key === 'Escape' && modalStack.current) {
    modalStack.current.close();
  }
});

const initModals = () => {
  const modals = {};

  document.querySelectorAll('.modal').forEach((modalElement) => {
    const modal = new Modal(modalElement);
    modals[modalElement.dataset.modal] = modal;
  });
}

export { initModals, modalStack }
