import { hideLoadingOverlay, showLoadingOverlay } from '../../utils/loadingScreen';
import { BASE_URL, postData } from '../api';
import { getTestType } from '../user/sendTestType';

const testType = getTestType();

const prodTitle = document.querySelector('[data-detail-title]');
const prodPrice = document.querySelector('[data-detail-price]');
const prodImg = document.querySelector('[data-detail-img]');
const prodSkinType = document.querySelector('[data-detail-type]');
const prodBrand = document.querySelector('[data-detail-brand]');
const prodComps = document.getElementById('comps');
const prodEffects = document.getElementById('effects');
const prodApplic = document.getElementById('recs');
const prodDescr = document.getElementById('descr');

async function getProductDetails(productCode) {
  showLoadingOverlay("Загружаются данные о товаре");
  try {
    const response = await postData(`${BASE_URL}/shop/product/?test_type=${testType}`, {
      'product_code': `${productCode}`
    });
    return response.data;

  } catch (error) {
  } finally {
    hideLoadingOverlay();
  }
}

function createProdDetailList(data, container) {
  if (data.length === 0) {
    const noDataElement = document.createElement('li');
    noDataElement.textContent = 'Нет данных';
    container.appendChild(noDataElement);
  } else {
    data.forEach(item => {
      const li = document.createElement('li');
      li.textContent = typeof item === 'object' && item.name ? item.name : item;
      container.appendChild(li);
    });
  }
}

async function updateProductDetails(details) {
  prodComps.innerHTML = ''
  prodEffects.innerHTML = ''
  prodApplic.innerHTML = ''
  prodDescr.innerHTML = ''
  prodTitle.textContent = details.name;
  prodPrice.textContent = details.price + ` ₽`;
  prodImg.src = details.img;
  prodSkinType.textContent = details.skin_type && details.skin_type.length > 0 ? details.skin_type.join(', ') : 'для всех';
  prodBrand.textContent = details.brand;

  createProdDetailList(details.components, prodComps);
  createProdDetailList(details.effects, prodEffects);
  createProdDetailList(details.applications, prodApplic);
  createProdDetailList([details.description], prodDescr);
}

function setProductDetails() {
  const prodDetailsBtns = document.querySelectorAll('[data-modal="details"]');
  if (prodDetailsBtns) {
    prodDetailsBtns.forEach(btn => btn.addEventListener('click', async (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const productCard = evt.target.closest('.card__prod')
      if (productCard) {
        const productCode = productCard.getAttribute('data-product-code');
        if (productCode) {

          const details = await getProductDetails(productCode);
          if (details) {
            await updateProductDetails(details);
          }
        }
      }
    }));
  }
}

export { setProductDetails };
