import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { moveToNextStage } from "../accessControl";
import { BASE_URL, getData } from "../api";
import { getTestType } from "../user/sendTestType";

const faceSkinIndex = document.querySelector('[data-result-index]');
const faceSkinType = document.querySelector('[data-result-type]');
const faceSkinCondition = document.querySelector('[data-result-cond]');
const faceSkinDiseasesList = document.querySelector('[data-result-diseases]');

const testType = getTestType();

async function getResults(type, testId) {
  showLoadingOverlay("Получаем результаты исследования");
  let url;
  if (type === 'usual') {
    url = `${BASE_URL}/core/test_result/?test_type=${testType}`;
  } else if (type === 'profile') {
    url = `${BASE_URL}/users/test?tp_id=${testId}`;
  }

  try {
    const response = await getData(url);

    moveToNextStage();
    const indexString = response.data.test_result;
    const resultCond = response.data.skin_state;
    const resultType = response.data.skin_type;
    const jsonString = indexString.replace(/'/g, '"');
    const resultDiseases = JSON.parse(jsonString);
    const resultIndex = resultDiseases.face_rank;

    faceSkinIndex.textContent = resultIndex;
    if (resultIndex >= 7) {
      faceSkinIndex.classList.add('good');
    } else if (resultIndex < 7 && resultIndex >= 5) {
      faceSkinIndex.classList.add('normal');
    } else {
      faceSkinIndex.classList.add('bad');
    }
    faceSkinType.textContent = resultType;
    faceSkinCondition.textContent = resultCond;

    for (const [key, value] of Object.entries(resultDiseases.face_conds)) {
      const listItem = document.createElement('li');
      const listItemText = document.createElement('span');
      const listItemLvl = document.createElement('div');
      const listItemBar = document.createElement('div');

      listItemLvl.classList.add('result__line');
      listItemBar.classList.add('result__bar');

      listItemText.textContent = `${key}`;
      listItemBar.style.width = `${value}%`;

      faceSkinDiseasesList.appendChild(listItem);
      listItem.appendChild(listItemText);
      listItem.appendChild(listItemLvl);
      listItemLvl.appendChild(listItemBar);
    }

    document.querySelectorAll('result__next[data-next]').forEach(button => {
      button.href = updateQueryParam(button.href, 'test_type', currentTestType);
    });

  } catch (error) {
  } finally {
    hideLoadingOverlay();
  }
}

export { getResults };
