import { redirectToPageWithParams } from "../../utils/redirectToPage";
import { BASE_URL, getData } from "../api";
import { Counter } from "../recs/manageRecsBasket";
import { getTestType } from "../user/sendTestType";

const totalSumElems = document.querySelectorAll('[data-total-sum]');
const totalAmountElems = document.querySelectorAll('[data-total-amount]');

const testType = getTestType();

async function getBasket() {
  try {
    const response = await getData(`${BASE_URL}/shop/basket?test_type=${testType}`);

    if (response.result && response.data.products) {
      return response.data;
    } else {
      return { products: [], total_price: 0, total_items: 0 };
    }
  } catch (error) {
    return { products: [], total_price: 0, total_items: 0 };
  }
}

function updateBasketState(basketInfo) {
  const { products, total_price, total_items } = basketInfo;

  products.forEach(item => {
    const productElement = document.querySelector(`[data-product-code="${item.code}"]`);
    if (productElement) {
      const counterInput = productElement.querySelector('[data-recs-counter-input]');
      if (counterInput) {
        const counterInstance = new Counter(productElement);
        counterInstance.update(item.count, false);
        if (item.count > 0) {
          counterInstance.openCounter();
        }
      }
    }
  });

  totalSumElems.forEach(item => {
    item.textContent = `${total_price.toFixed(2)} ₽`;
  });

  totalAmountElems.forEach(item => {
    item.textContent = `${total_items} шт.`;
    if (total_items > 0) {
      item.classList.add('is-full');
      document.querySelectorAll('[data-total-btn]').forEach(btn => {
        btn.classList.remove('is-disabled');
        btn.addEventListener('click', () => redirectToPageWithParams('order.html', ''));
      });
    } else {
      item.classList.remove('is-full');
      document.querySelectorAll('[data-total-btn]').forEach(btn => {
        btn.classList.add('is-disabled');
        btn.removeEventListener('click', () => redirectToPageWithParams('order.html', ''));
      });
    }
  });
}


export { getBasket, updateBasketState }