function redirectToPageWithParams(page, paramKey) {
  const params = new URLSearchParams(window.location.search);
  const paramValue = params.get(paramKey);
  if (paramValue) {
    window.location.href = `${page}?${paramKey}=${paramValue}`;
  } else {
    window.location.href = page;
  }
}

export { redirectToPageWithParams }