import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { BASE_URL, postData } from "../api";
import { getTestType } from "../user/sendTestType";

const sendBrandsBtn = document.querySelector('[data-filters-accept]');
const resetFiltersBtn = document.querySelector('[data-filters-reset]');
const testSkipped = localStorage.getItem('testSkipped') === 'true'
const skinFiltersContainer = document.querySelector('[data-filters-skin]')

let selectedBrands = JSON.parse(localStorage.getItem('selectedBrands')) || [];

// if(testSkipped) {
//   skinFiltersContainer.style.display = 'flex'
// }



function getBrandsList(brands) {
  const brandsListCont = document.querySelector("[data-filters-brands]");

  if (!brandsListCont || !brands) {
    return;
  }

  brands.forEach((brand) => {
    const label = document.createElement("li");
    label.innerHTML = `
      <label class="modal__filters-prod">
        <input type="checkbox" name="selected_brand" value="${brand}">
        <span class="modal__filters-check"></span>
        <span class="modal__filters-name">${brand}</span>
      </label>
    `;
    brandsListCont.append(label);
  });

  selectedBrands.forEach((brand) => {
    const checkbox = brandsListCont.querySelector(`input[value="${brand}"]`);
    if (checkbox) {
      checkbox.checked = true;
    }
  });

  const searchInput = document.querySelector(".brand-search");
  if (searchInput) {
    searchInput.addEventListener("input", function () {
      const filter = searchInput.value.toLowerCase();
      const brands = brandsListCont.querySelectorAll("li");

      brands.forEach((brand) => {
        const brandName = brand.textContent.toLowerCase();
        if (brandName.includes(filter)) {
          brand.style.display = "";
        } else {
          brand.style.display = "none";
        }
      });
    });
  }

  if (brandsListCont) {
    brandsListCont.addEventListener("change", function (event) {
      const checkbox = event.target;
      if (checkbox.name === "selected_brand") {
        const brandName = checkbox.value;

        if (checkbox.checked) {
          selectedBrands.push(brandName);
        } else {
          selectedBrands = selectedBrands.filter((name) => name !== brandName);
        }

        localStorage.setItem('selectedBrands', JSON.stringify(selectedBrands));
      }
    });
  }

  if (sendBrandsBtn) {
    sendBrandsBtn.addEventListener('click', sendBrands);
  }

  if (resetFiltersBtn) {
    resetFiltersBtn.addEventListener('click', () => {
      resetFilters();
      sendBrands();
    });
  }
}

function resetFilters() {
  const brandsListCont = document.querySelector("[data-filters-brands]");
  const checkboxes = brandsListCont.querySelectorAll('input[type="checkbox"]');
  const searchInput = document.querySelector(".brand-search");

  checkboxes.forEach(checkbox => {
    checkbox.checked = false;
  });

  selectedBrands = [];
  localStorage.setItem('selectedBrands', JSON.stringify(selectedBrands));

  if (searchInput) {
    searchInput.value = '';
  }

  const brands = brandsListCont.querySelectorAll("li");
  brands.forEach(brand => {
    brand.style.display = '';
  });
}

async function sendBrands() {
  showLoadingOverlay('Фильтруем бренды');
  const testType = getTestType();

  try {
    const response = await postData(`${BASE_URL}/core/test_result/?test_type=${testType}`,
      {
        "filter_type": "brand",
        "data": {
          "brands": selectedBrands
        }
      }
    );
    if (response.result) {
      location.reload();
    }
  } catch (error) {
    console.error(error);
  } finally {
    hideLoadingOverlay();
  }
}

export { getBrandsList, selectedBrands };
