import Swiper from 'swiper';

function initSlider(sliderElement, prevSelector, nextSelector) {
  const prevBtn = document.querySelector(prevSelector);
  const nextBtn = document.querySelector(nextSelector);

  if (sliderElement) {
    const swiper = new Swiper(sliderElement, {
      breakpoints: {
        350: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
      }
    });

    checkSlideStatus(swiper);

    swiper.on('slideChange', function () {
      checkSlideStatus(swiper);
    });

    function checkSlideStatus(swiper) {
      if (swiper.isBeginning) {
        prevBtn.style.display = 'none';
      } else {
        prevBtn.style.display = 'flex';
      }
      if (swiper.isEnd) {
        nextBtn.style.display = 'none';
      } else {
        nextBtn.style.display = 'flex';
      }
    }

    if (nextBtn) {
      nextBtn.addEventListener('click', () => swiper.slideNext());
    }
    if (prevBtn) {
      prevBtn.addEventListener('click', () => swiper.slidePrev());
    }
  }
}

function initMorningSlider() {
  initSlider(document.querySelector('[data-swiper-morning]'), '.recs__care-morning .swiper-button-prev', '.recs__care-morning .swiper-button-next');
}

function initEveningSlider() {
  initSlider(document.querySelector('[data-swiper-evening]'), '[data-swiper-evening] .swiper-button-prev', '[data-swiper-evening] .swiper-button-next');
}

function initAdditSlider() {
  initSlider(document.querySelector('[data-swiper-addit]'), '[data-swiper-addit] .swiper-button-prev', '[data-swiper-addit] .swiper-button-next');
}

export { initMorningSlider, initEveningSlider, initAdditSlider };
