import axios from "axios";
import { BASE_URL } from "../api";

async function getUserToken() {
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    return accessToken;
  }

  try {
    const response = await axios.get(`${BASE_URL}/users/temp_user_create/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const tempToken = response.data.temp_token;
    localStorage.setItem("userToken", tempToken);
    return tempToken;
  } catch (error) {
  }
}

function useToken() {
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    return accessToken;
  }

  const tempToken = localStorage.getItem("userToken");
  if (tempToken) {
    return tempToken;
  } else {
    return null;
  }
}

export { useToken, getUserToken };
