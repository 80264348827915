const initSideNav = () => {
  const testTypeToText = {
    face: "Лицо",
    nails: "Ногти",
    skin: "Кожа",
    hair: "Волосы",
  };

  const params = new URLSearchParams(window.location.search);
  const currentTestType = params.get("test_type");
  const headingText = testTypeToText[currentTestType] || "Выберите тип тестирования";
  const heading = document.querySelector(".sidemenu h2");
  const menuLinks = document.querySelectorAll(".sidemenu a");

  if (heading) {
    heading.textContent = headingText;
  }

  menuLinks.forEach((link) => {
    const url = new URL(link.href, window.location.origin);
    if (currentTestType) {
      url.searchParams.set("test_type", currentTestType);
    }
    link.href = url;
  });

  const currentActiveLink = document.querySelector(".sidemenu__current");
  if (currentActiveLink) {
    currentActiveLink.scrollIntoView({
      behavior: 'smooth',
      inline: 'center'
    });
  }
};

export { initSideNav };
