function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('../../sprite/', true, /\.svg$/));

function loadSprite() {
  var xhr = new XMLHttpRequest();

  xhr.open('GET', '../images/sprite/sprite.svg', true);
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  xhr.onreadystatechange = function() {
    if (xhr.readyState !== 4) return false;

    document.getElementById('svg-icons').innerHTML = xhr.responseText;
  };

  xhr.send();
}

loadSprite();
