import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { getBasket } from "./getOrderBasket";

const orderList = document.querySelector('[data-order-list]');
const orderDate = document.querySelector('[data-order-date]');
const orderNum = document.querySelector('[data-order-num]');
const orderShop = document.querySelector('[data-order-shop]');
const orderAmount = document.querySelector('[data-order-amount]');
const orderSum = document.querySelector('[data-order-sum]');
const orderBackLinks = document.querySelectorAll('[data-order-back]')

class OrderProduct {
  constructor(img, name, price, count) {
    this.img = img
    this.name = name
    this.price = price
    this.count = count
  }

  render() {
    const product = document.createElement('li')
    product.innerHTML = `
      <div class="order__data">
        <img src=${this.img} alt="Изображение продукта." width="94" height="94">
        <p data-order-text>${this.name}</p>
      </div>
      <div class="order__price">
        <span>${this.price} ₽</span>
        <p>${this.count} шт.</p>
      </div>
    `
    orderList.append(product)
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  return date.toLocaleDateString('ru-RU', options);
}

async function renderOrderData() {
  showLoadingOverlay('Загружаем данные заказа');

  try {
    const orderData = await getBasket();

    if (orderNum) {
      orderNum.textContent = 'Ваш заказ №' + orderData.order_num;
    }

    if (orderDate) {
      orderDate.textContent = 'От ' + formatDate(orderData.created_at);
    }

    if (orderShop) {
      orderShop.textContent = orderData.shop.address;
    }

    if (orderAmount) {
      orderAmount.textContent = `${orderData.total_items || 0} шт.`;
    }

    if (orderSum) {
      orderSum.textContent = `${orderData.total_price.toFixed(2) || '0.00'} ₽`;
    }

    if (orderList && orderData.products) {
      orderList.innerHTML = '';

      orderData.products.forEach(product => {
        const { img, name, price, count } = product

        const orderCard = new OrderProduct(
          img,
          name,
          price,
          count
        )
        orderCard.render()
      })
    }
  } catch (error) {
    console.error('Не удалось получить данные заказа', error);
  } finally {
    hideLoadingOverlay()
  }
}

orderBackLinks.forEach(link => {
  if(link) {
    link.addEventListener('click', function(evt) {
      evt.preventDefault();
      window.history.back();
    })
  }
})

export { renderOrderData }
