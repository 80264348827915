const burgerButton = document.querySelector('[data-js="burger"]');
const headerMenu = document.querySelector('[data-js="headerMenu"]');

const openHeaderMenu = () => {
  if (burgerButton && headerMenu) {
    if (!burgerButton.classList.contains('event-added')) {
      burgerButton.addEventListener('click', () => {
        burgerButton.classList.toggle('is-closed');
        headerMenu.classList.toggle('is-closed');
      });
      burgerButton.classList.add('event-added');
    }
  }

  document.addEventListener('click', (event) => {
    if (burgerButton && headerMenu) {
      if (!headerMenu.contains(event.target) && !burgerButton.contains(event.target)) {
        if (!headerMenu.classList.contains('is-closed')) {
          headerMenu.classList.add('is-closed');
          burgerButton.classList.add('is-closed');
        }
      }
    }
  });

  headerMenu.addEventListener('click', (event) => {
    if (!headerMenu.classList.contains('is-closed')) {
      headerMenu.classList.add('is-closed');
      burgerButton.classList.add('is-closed');
    }
  });
};

export { openHeaderMenu }