import { copyToClipboard } from '../../utils/copyToClipboard'

const urlList = {
  facebook: 'https://facebook.com/sharer/sharer.php?u=',
  vk: 'https://vk.com/share.php?url=',
  twitter: 'http://twitter.com/share?url=',
  ok: 'https://connect.ok.ru/offer?url=',
  telegram: 'https://t.me/share/url?url=',
  whatsapp: 'https://api.whatsapp.com/send?text=',
}

function getUrl(social, url) {
  if (urlList[social]) {
    return urlList[social] + encodeURIComponent(url);
  }

  return null;
}

function openWindow(url, title = 'Поделиться', features = 'width=640,height=480,location=no,toolbar=no,menubar=no') {
  return window.open(url, title, features);
}

function facebook(url) {
  return openWindow(getUrl('facebook', url));
}

function vk(url) {
  return openWindow(getUrl('vk', url));
}

function twitter(url) {
  return openWindow(getUrl('twitter', url));
}

function ok(url) {
  return openWindow(getUrl('ok', url));
}

function telegram(url) {
  return openWindow(getUrl('telegram', url));
}

function whatsapp(url) {
  return openWindow(getUrl('whatsapp', url));
}

function initSharing() {
  const shareButtons = document.querySelectorAll('[data-share]');

  [].forEach.call(shareButtons, (link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();

      const url = location.origin + location.pathname;

      switch (event.currentTarget.dataset.share) {
        case 'face':
          facebook(url);
          break;

        case 'vk':
          vk(url);
          break;

        case 'twit':
          twitter(url);
          break;

        case 'ok':
          ok(url);
          break;

        case 'tg':
          telegram(url);
          break;

        case 'whats':
          whatsapp(url);
          break;
      }
    });
  });
}

function initShareCopy() {
  const copyBtn = document.querySelector('[data-share-copy]');
  if (!copyBtn) {
    return;
  }

  const copyText = location.href;

  copyBtn.addEventListener('click', () => {
    copyToClipboard(copyText)

    copyBtn.classList.add('-copied');
    setTimeout(() => {
      copyBtn.classList.remove('-copied');
    }, 1000)
  });
}

initShareCopy();
initSharing();

export { initSharing };
