function checkBottomMenu() {
  if (window.innerWidth < 768) {
    const bottomBar = document.querySelector('.fixed__bottom');
    const footerContainer = document.querySelector('.footer__container');
    if (bottomBar && footerContainer) {
      const bottomBarHeight = bottomBar.offsetHeight;
      footerContainer.style.marginBottom = bottomBarHeight + 'px';
    }
  }
}

export { checkBottomMenu }