let countdownInterval;

const timerElement = document.getElementById('timer');
const timerContainer = document.getElementById('timerContainer');

function startCountdown(duration) {
  const requestCodeButton = document.querySelector('[data-login-repeat-code]');
  let time = duration;
  updateTimerDisplay(time);

  if (requestCodeButton) {
    requestCodeButton.disabled = true;
    timerContainer.style.display = 'block';

    countdownInterval = setInterval(() => {
      time--;
      updateTimerDisplay(time);

      if (time <= 0) {
        clearInterval(countdownInterval);
        requestCodeButton.disabled = false;
        timerContainer.style.display = 'none';
        timerElement.textContent = '';
      }
    }, 1000);
  } else {
    console.error("Request code button not found");
  }
}

function updateTimerDisplay(seconds) {
  const minutes = Math.floor(seconds / 60);
  const secondsPart = seconds % 60;

  if (timerElement && timerContainer) {
    timerElement.textContent = `${minutes.toString().padStart(2, '0')}:${secondsPart.toString().padStart(2, '0')}`;
  }
}

export { startCountdown }