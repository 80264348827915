import axios from 'axios';
import { hideLoadingOverlay, showLoadingOverlay } from '../../utils/loadingScreen';
import { redirectToPageWithParams } from '../../utils/redirectToPage';
import { BASE_URL } from '../api';
import { useToken } from '../user/getUserToken';
import { getTestType } from '../user/sendTestType';

const testType = getTestType();

export class Counter {
  constructor(container, config = {}) {
    this.container = container;
    this.config = config;
    this.productCode = this.container.dataset.productCode;

    this.idleButton = this.container.querySelector('[data-recs-add]');
    this.decreaseBtn = this.container.querySelector('[data-recs-decr]');
    this.increaseBtn = this.container.querySelector('[data-recs-incr]');
    this.input = this.container.querySelector('[data-recs-counter-input]');
    this.counter = this.container.querySelector('[data-recs-counter-cont]');

    this.value = parseInt(this.input.value, 10) || 0;
    this.price = parseFloat(this.container.querySelector('.card__prod-price').textContent.replace('₽', '').trim());

    this.addListeners();
    this.update(this.value, false);
  }

  addListeners() {
    if (this.idleButton && !this.idleButton.hasListener) {
      this.idleButton.addEventListener('click', () => {
        this.openCounter();
        this.increase();
      });
      this.idleButton.hasListener = true;
    }

    if (this.increaseBtn && !this.increaseBtn.hasListener) {
      this.increaseBtn.addEventListener('click', () => {
        this.increase();
      });
      this.increaseBtn.hasListener = true;
    }

    if (this.decreaseBtn && !this.decreaseBtn.hasListener) {
      this.decreaseBtn.addEventListener('click', () => {
        this.decrease();
      });
      this.decreaseBtn.hasListener = true;
    }
  }

  async increase() {
    const token = useToken();
    this.update(this.value + 1);
    await this.sendRequest('PUT', this.value, {
      'Content-Type': 'application/json',
      'J-Authorization': `${token}`
    }, true);
  }

  async decrease() {
    const token = useToken();
    if (this.value > 0) {
      this.update(this.value - 1);
      await this.sendRequest('PUT', this.value, {
        'Content-Type': 'application/json',
        'J-Authorization': `${token}`
      }, true);
    }
  }

  update(newValue, emit = false) {
    this.value = newValue;
    this.input.value = this.value;

    if (this.value !== 0) {
      this.container.classList.add('is-active');
    } else {
      this.container.classList.remove('is-active');
    }

    if (this.config.onUpdate && emit) {
      this.config.onUpdate(newValue);
    }
  }

  closeCounter() {
    if (this.idleButton) {
      this.idleButton.style.display = 'block';
    }
    if (this.counter) {
      this.counter.style.display = 'none';
    }
  }

  openCounter() {
    if (this.idleButton) {
      this.idleButton.style.display = 'none';
    }
    if (this.counter) {
      this.counter.style.display = 'flex';
    }
  }

  async sendRequest(method, count, headers) {

    showLoadingOverlay('Меняем содержимое корзины')
    const url = `${BASE_URL}/shop/basket/?product_code=${this.productCode}&count=${count}&test_type=${testType}`;

    try {
      const response = await axios({
        method: method,
        url: url,
        headers: headers
      });
      const result = response.data;
      if (result.result !== true) {
        console.error('Ошибка при обновлении корзины', result);
      } else {
        if (count === 0) {
          this.closeCounter();
        }
        this.update(count, false)
        this.updateTotal();
      }
    } catch (error) {
    } finally {
      hideLoadingOverlay()
    }
  }

  async updateTotal() {
    const totalSumElems = document.querySelectorAll('[data-total-sum]');
    const totalAmountElems = document.querySelectorAll('[data-total-amount]');
    const allCounters = document.querySelectorAll('[data-recs-counter-input]');
    const totalBtns = document.querySelectorAll('[data-total-btn]');

    let totalSum = 0;
    let totalAmount = 0;

    allCounters.forEach(counter => {
      const value = Number(counter.value);
      const container = counter.closest('[data-recs-prod]');
      const price = parseFloat(container.querySelector('.card__prod-price').textContent.replace('₽', '').trim());

      totalAmount += value;
      totalSum += value * price;
    });

    totalSumElems.forEach(item => {
      item.textContent = `${totalSum.toFixed(2)} ₽`;
    });
    totalAmountElems.forEach(item => {
      item.textContent = `${totalAmount} шт.`;
      if (totalAmount > 0) {
        item.classList.add('is-full');
        totalBtns.forEach(btn => {
          btn.classList.remove('is-disabled');
          btn.addEventListener('click', () => redirectToPageWithParams('order.html', ''));
        });
      } else {
        item.classList.remove('is-full');
        totalBtns.forEach(btn => {
          btn.classList.add('is-disabled');
          btn.removeEventListener('click', () => redirectToPageWithParams('order.html', ''));
        });
      }
    });
  }

  async deleteProdFromBasket(code) {
    showLoadingOverlay('Чистим корзину');
    const token = useToken();

    try {
      const response = await axios.delete(`${BASE_URL}/shop/basket/`, {
        params: {
          product_code: code,
          test_type: testType
        },
        headers: {
          'Content-Type': 'application/json',
          'J-Authorization': `${token}`
        }
      });

      if (response.data.result) {
        this.update(0);
        this.closeCounter();
        await this.updateTotal();
      }
    } catch (error) {
    } finally {
      hideLoadingOverlay();
    }
  }
}

