import { hideLoadingOverlay, showLoadingOverlay } from '../../utils/loadingScreen';
import { redirectToPageWithParams } from '../../utils/redirectToPage';
import { hideModal } from '../../utils/showSkipScreen';
import { moveToNextStage } from '../accessControl';
import { BASE_URL, postData } from '../api';
import { getTestType } from '../user/sendTestType';

const MAX_FILE_SIZE = 10 * 1024 * 1024;
const MAX_WIDTH = 10000;
const MAX_HEIGHT = 10000;
const MIN_WIDTH = 330;
const MIN_HEIGHT = 200;

const input = document.querySelector('[data-photo-input]');
const previewImage = document.querySelector('[data-photo-image]');
const usePhotoButtons = document.querySelectorAll('[data-photo-use]');
const errorContainer = document.querySelector('[data-photo-error]');
const validFileTypes = ['image/jpeg', 'image/png', 'image/webp'];
const progressBar = document.querySelector('[data-analysis-bar]');
const scanLine = document.querySelector('[data-analysis-line]');
const analysisStatus = document.querySelector('[data-analysis-status] p');
const analysisStatusPercent = document.querySelector('[data-analysis-status] span');
const photoElement = document.querySelector('img[data-photo]');

const testType = getTestType();

function initPhotoUpload() {
  document.addEventListener('click', async function(event) {
    if (event.target.matches('[data-warning-btn2]')) {
      await markPhotoAsSkipped();
      await sendPhoto("skip").then(() => {
        moveToNextStage();
        redirectToPageWithParams('test.html', 'test_type');
      });
    } else if(event.target.matches('[data-warning-btn1]')) {
      hideModal('warningModal1')
    }
  });

  function validateFile(file) {
    if (!validFileTypes.includes(file.type)) {
      throw new Error('Недопустимый формат файла. Допускаются только jpeg, jpg, png, webp.');
    }
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('Файл слишком большой. Размер файла не должен превышать 10 МБ.');
    }
  }

  function validateImageSize(imageSrc) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
          reject(new Error(`Изображение слишком маленькое. Минимальный размер - ${MIN_WIDTH}x${MIN_HEIGHT} пикселей. Текущий размер - ${img.width}x${img.height}`));
        } else if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
          reject(new Error(`Изображение слишком большое. Максимальный размер - ${MAX_WIDTH}x${MAXHEIGHT} пикселей. Текущий размер - ${img.width}x${img.height}`));
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        reject(new Error('Ошибка при загрузке изображения'));
      };
      img.src = imageSrc;
    });
  }

  function updatePreview(imageSrc) {
    previewImage.innerHTML = '';
    const img = document.createElement('img');
    img.setAttribute('alt', 'Ваше фото');
    img.src = imageSrc;
    previewImage.appendChild(img);
    usePhotoButtons.forEach((btn) => btn.classList.remove('visually-hidden'));
    photoElement.src = imageSrc;
  }

  if (input) {
    input.addEventListener('change', (event) => {
      const file = event.target.files[0];
      try {
        validateFile(file);
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            await validateImageSize(e.target.result);
            updatePreview(e.target.result);
          } catch (error) {
            errorContainer.textContent = error.message;
            usePhotoButtons.forEach((btn) => btn.classList.add('visually-hidden'));
          }
        };
        reader.readAsDataURL(file);
      } catch (error) {
        errorContainer.textContent = error.message;
        usePhotoButtons.forEach((btn) => btn.classList.add('visually-hidden'));
      }
    });
  }

  async function sendPhoto(data) {
    showLoadingOverlay('Получаем фотографию');
    try {
      const response = await postData(`${BASE_URL}/core/test_photo/?test_type=${testType}`, {
        photo: data,
      });

      if (response.result) {
        if (typeof data === 'string' && data.startsWith('data:image')) {
          await markPhotoAsUploaded();
        }
        return response.result;
      } else {
        return;
      }
    } catch (error) {
    } finally {
      hideLoadingOverlay();
    }
  }


  usePhotoButtons.forEach((btn) => {
    btn.addEventListener('click', async (event) => {
      event.preventDefault();
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        const base64Image = reader.result;
        const answer = await sendPhoto(base64Image);
        startAnalysis(answer);
      };
      reader.readAsDataURL(file);
    });
  });

  function startAnalysis(isSuccess) {
    let progress = 0;
    progressBar.style.width = '0%';
    let lastTime;

    function animateProgress(timestamp) {
      if (!lastTime) lastTime = timestamp;
      const progressIncrease = 1;
      const timeThreshold = 20

      if (timestamp - lastTime > timeThreshold) {
        progress += progressIncrease;
        lastTime = timestamp;

        progressBar.style.width = `${progress}%`;
        scanLine.style.top = `${progress}%`;
        analysisStatus.textContent = 'Анализируем вашу фотографию...';
        analysisStatusPercent.textContent = `${progress}%`;
      }

      if (progress < 100) {
        requestAnimationFrame(animateProgress);
      } else {
        analysisStatus.textContent = 'Анализ завершен!';
        if (isSuccess) {
          moveToNextStage();
          redirectToPageWithParams('test.html', 'test_type');
        } else {
          redirectToPageWithParams('photo-error.html', 'test_type');
        }
      }
    }

    requestAnimationFrame(animateProgress);
  }
}

async function markPhotoAsUploaded() {
  localStorage.setItem('photoUploaded', 'true');
}

async function markPhotoAsSkipped() {
  localStorage.setItem('photoUploaded', 'false');
}

export { initPhotoUpload };
